/*
 * @Author: gaojiahao 3055320775@qq.com
 * @Date: 2023-11-08 09:10:43
 * @LastEditors: gaojiahao 3055320775@qq.com
 * @LastEditTime: 2024-07-26 17:59:45
 * @FilePath: \属地三期\src\api\troubleshoot\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * im[]
 */
import request from '../request';
import HCrequest from '../hcRequest'
//排查内容
export function checkContent(data) {
  return request({
  //   url:'https://api.sd.wangxinban.cn/api/misre/ordinary',
  //   // http://121.40.83.215:8001/misre/ordinary
  //   // url:'http://121.40.83.215:8001/misre/ordinary/v2',
  //   // http://121.40.83.215:8001/misre/AJDapi
  //   // https://api.sd.wangxinban.cn/api/misre/AJDapi
  //   // https://api.sd.wangxinban.cn/api/misre/ordinary
    // url:'http://118.31.16.203:8099/handle', 
    url:'https://api.sd.wangxinban.cn/check',
    method: 'post',
    data:{
      content:data.content
    }
  })
  // return HCrequest({
  //   url:'handle',
  //   method:'post',
  //   data
  // })
}