/*
 * @Author: gaojiahao 3055320775@qq.com
 * @Date: 2024-07-24 16:31:59
 * @LastEditors: gaojiahao 3055320775@qq.com
 * @LastEditTime: 2024-07-24 16:37:08
 * @FilePath: \属地三期\src\api\hcRequest.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import ElementUI from "element-ui";
import router from "../router";

const service = axios.create({
  baseURL: '/hcapi'
});

service.interceptors.request.use(
  (config) => {
    // 一般在这个位置判断token是否存在

    // window.localStorage.setItem("baseURL", baseURL); //导入请求地址  正式
    // window.localStorage.setItem("baseURL", peizhi().baseURL); //导入请求地址  测试
    const token = "Bearer " + window.localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

//响应拦截
service.interceptors.response.use(
  (response) => {
    // 处理响应数据
    if (response.data.code === 200) {
      return Promise.resolve(response);
    } else if (response.data.code === 400) {
      ElementUI.Message({
        message: response.data.msg,
        type: "warning",
      });
      router.push({
        path: "/login",
      });
    } else if (response.data.code === 500) {
      // console.log('接口500了');
      ElementUI.Message({
        message: response.data.msg,
        type: "warning",
      });
    } else if (!response.data.code) {
      // console.log("导出流信息");
      return Promise.resolve(response);
    } else {
      if (response.data.msg) {
        ElementUI.Message({
          message: response.data.msg,
          type: "error",
        });
      }

      return Promise.resolve(response);
    }
  },
  // 处理响应失败
  (error) => Promise.reject(error)
);
export default service;
